.create_audience_modal {
    border: none;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    overflow-y: auto;
    position: absolute;
    z-index: 100;
    background-color: white;
    width: 75%;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    max-height: 90%;
    max-width: 600px;
    
}

.panel_header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: #00BADB;
    font-size: 1.5rem;
    color: white;
}

.modal_button {
    border: none;
    padding: 0.7rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.modal_button:hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.submit_button {
    color: white;
    background-color: #00BADB;
}

.cancel_button {
    background-color: white;
    margin-right: 1rem;
}
