.container {
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.column {
  position: relative;
  width: 50%;
  padding: 24px;
}

.columnCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  padding: 24px;
}

.mobileContainer {
  height: 667px;
  width: 375px;
}
