.container {
  position: relative;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.column {
  position: relative;
  width: 50%;
  padding: 24px;
}

.FeedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Feed {
  display: flex;
  height: 555px;
  width: 312px;
  padding-left: 8px;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica,
    ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro, メイリオ, Meiryo,
    ＭＳ Ｐゴシック, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
    Segoe UI Symbol;
  overflow: hidden;
}

.FeedCol {
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
  height: 110%;
}

.FeedBlock {
  display: flex;
  width: 100%;
}

.FeedItem {
  margin-right: 8px;
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
}

.FeedItemImage {
  width: 100%;
  background-color: rgb(236, 239, 241);
  border-radius: 8px;
  flex-grow: 1;
}

.FeedItemText {
  min-height: 8px;
}

.Stim {
  margin-right: 8px;
  width: calc(100% - 8px);
  margin-bottom: 8px;
}

.StimImage > img {
  width: 100%;
  border-radius: 8px;
}

.StimText {
  max-height: 40px;
  display: flex;
  text-align: left;
  max-width: 100%;
  line-height: 1;
}

.StimTitle {
  margin: 0.25rem;
  font-size: 0.6rem;
  font-weight: bold;
  flex-grow: 1;
  overflow-wrap: break-word;
  overflow: hidden;
}

.StimMore {
  margin: 0 0.25rem;
  margin-top: -0.25rem;
  height: 0.6rem;
}

.StimPromo {
  display: flex;
}

.StimIcon {
  margin: 0.25rem;
  height: 32px;
  width: 32px;
  background-color: black;
  border-radius: 17px;
}

.StimIcon > img {
  width: 100%;
  height: 100%;
  border-radius: 17px;
}

.StimProfile {
  flex-grow: 1;
  font-size: 0.6rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
  margin-left: 4px;
  justify-content: center;
  line-height: 1;
}

.StimProfile > :first-child {
  font-weight: bold;
}
